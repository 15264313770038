<template>
  <loader v-if="globalLoading"></loader>
  <alert-error v-if="globalError"></alert-error>
  <alert-success v-if="globalSuccess"></alert-success>
  <div class="min-h-screen bg-white">
    <nav class="bg-white border-b border-gray-200">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex justify-between h-16">
          <div class="flex">
            <router-link to="/" class="flex-shrink-0 flex items-center">
              Dolphin
            </router-link>
          </div>
          <button
            v-if="isAuthenticated"
            @click="logout"
            tag="button"
            class="router-link-active router-link-exact-active bg-white mt-4 py-2 px-4 h-10 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Logout
          </button>
        </div>
      </div>

      <!-- Mobile menu, show/hide based on menu state. -->
      <div class="sm:hidden" id="mobile-menu">
        <div class="pt-2 pb-3 space-y-1">
          <a
            href="#"
            class="bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
            aria-current="page"
          >
            Menus
          </a>
          <a
            href="#"
            class="border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
          >
            Logs
          </a>
        </div>
      </div>
    </nav>

    <div class="py-10">
      <router-view />
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import Loader from "@/components/Loader";
import AlertError from "@/components/AlertError";
import AlertSuccess from "@/components/AlertSuccess";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "App",
  components: {
    Loader,
    AlertError,
    AlertSuccess,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const profile = computed(() => store.state.user.profile);
    const isAuthenticated = computed(() => !!profile.value.uid);

    const logout = () => {
      store.dispatch("logout");
      router.push("/login");
    };

    return {
      globalError: computed(() => store.state.alerts.error),
      globalSuccess: computed(() => store.state.alerts.success),
      globalLoading: computed(() => store.state.alerts.loading),
      logout,
      isAuthenticated,
    };
  },
};
</script>
