const state = {
    error: '',
    success: '',
    loading: false,
};

const getters = {
    globalError: state => state.error,
    globalSuccess: state => state.success,
    globalLoading: state => state.loading,
};

const actions = {
    setError({ commit }, value) {
        commit("SET_ERROR", value);
        setTimeout(function () { commit("SET_ERROR", ''); }, 2000);
    },
    setSuccess({ commit }, value) {
        commit("SET_SUCCESS", value);
        setTimeout(function () { commit("SET_SUCCESS", ''); }, 2000);
    },
    setLoading({ commit }, value) {
        commit("SET_LOADING", value);
    }
};

const mutations = {
    SET_ERROR: (state, value) => {
        state.error = value;
    },
    SET_SUCCESS: (state, value) => {
        state.success = value;
    },
    SET_LOADING: (state, value) => {
        state.loading = value;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};