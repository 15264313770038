const userProfile = {
    uid: "",
    email: "",
    displayName: "",
};

const state = {
    profile: JSON.parse(localStorage.getItem("profile")) || userProfile,
};

const getters = {
    isAuthenticated: state => !!state.profile.uid,
    getProfile: state => state.profile,
};

const actions = {
    auth({ commit }, data) {
        commit("PROFILE_UPDATE", data);
    },
    logout({ commit }) {
        commit("PROFILE_UPDATE", {});
    }
};

const mutations = {
    PROFILE_UPDATE: (state, user) => {
        state.profile = { ...userProfile, ...user };
        localStorage.setItem("profile", JSON.stringify(state.profile));
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};