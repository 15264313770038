import { createRouter, createWebHistory } from 'vue-router'
import { computed } from "vue";
import store from "@/store";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login")
  },
  {
    path: '/',
    name: 'Menus',
    component: () => import('@/views/Menus'),
    meta: { requiresAuth: true },
  },
  {
    path: '/menu-add',
    name: 'MenuAdd',
    component: () => import('@/views/MenuAdd'),
    meta: { requiresAuth: true },
  },
  {
    path: '/menu-edit/:id',
    name: 'MenuEdit',
    component: () => import('@/views/MenuEdit'),
    meta: { requiresAuth: true },
  },
  {
    path: '/menu-logs/:id',
    name: 'MenuLogs',
    component: () => import('@/views/MenuLogs'),
    meta: { requiresAuth: true },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {

  const profile = computed(() => store.state.user.profile);
  const isAuthenticated = computed(() => !!profile.value.uid);

  // Not logged into a guarded route?
  if (to.meta.requiresAuth && !isAuthenticated.value)
    next({ name: "Login" });

  // Logged in for an auth route
  else if (!to.meta.requiresAuth && isAuthenticated.value)
    next({ name: "Menus" });

  // Carry On...
  else next();

});


export default router
